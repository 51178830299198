<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('user.users') }}</h2>
    </div>

    <div class="filter-container">
      <el-row>
        <el-col :span="4" style="margin: 10px;">
          <vs-card class="success-card" @click="havingRole('admin')">
            <template #title>
              <h3>{{ $t('general.admins') }}</h3>
            </template>
            <template #text>
              <h2><count-to :start-val="0" :end-val="adminsCount" :duration="3000" class="card-panel-num" /></h2>
            </template>
          </vs-card>
        </el-col>
        <el-col :span="4" style="margin: 10px;">
          <vs-card class="secondary-card" @click="excludeRole('admin')">
            <template #title>
              <h3>{{ $t('general.others') }}</h3>
            </template>
            <template #text>
              <h2><count-to :start-val="0" :end-val="othersCount" :duration="3000" class="card-panel-num" /></h2>
            </template>
          </vs-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import UserResource from '@/api/user';
import RoleResource from '@/api/role';
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import checkPermission from '@/utils/permission'; // Vérification Permission
import CountTo from 'vue-count-to';
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const userResource = new UserResource();
const permissionResource = new Resource('permissions');
const roleResource = new RoleResource();
export default {
  name: 'UserList',
  components: { CountTo },
  directives: { waves, permission },
  data() {
    var validatec_password = (rule, value, callback) => {
      if (value !== this.currentUser.password) {
        callback(new Error(this.$t('user.PasswordsInconform')));
      } else {
        callback();
      }
    };
    return {
      adminsCount: 0,
      donorsCount: 0,
      recipientsCount: 0,
      othersCount: 0,
      editCredentials: false,
      editing: false,
      activeName: '',
      list: null,
      total: 0,
      loading: true,
      downloading: false,
      userCreating: false,
      permissions: [],
      checkedPermissionsId: [],
      filtredPermissions: [],
      normalizedPermissionNames: [],
      normalizedPermissions: [],
      allnormalizedPermissions: [],
      selectedPermissionNames: [],
      permissionsHeritedFromRole: [],
      query: {
        page: 1,
        limit: 15,
        keyword: '',
        role: '',
      },
      roles: [],
      nonAdminRoles: [],
      currentUser: {},
      currentUserNoHeritedPermissions: [],
      activeList: 'disponibles',
      dialogCreateUser: false,
      dialogPermissionVisible: false,
      dialogPermissionLoading: false,
      currentUserId: 0,
      rules: {
        roles: [{ required: true, message: this.$t('user.RoleRequired'), trigger: 'change' }],
        name: [{ required: true, message: this.$t('user.NomRequired'), trigger: 'blur' }],
        email: [
          { required: true, message: this.$t('user.EmailRequired'), trigger: 'blur' },
          { type: 'email', message: this.$t('user.EmailInvalide'), trigger: ['blur', 'change'] },
        ],
        password: [{ required: true, message: this.$t('user.PasswordRequired'), trigger: 'blur' }, { min: 6, message: this.$t('user.PasswordMin6'), trigger: 'blur' }],
        c_password: [{ validator: validatec_password, trigger: 'blur' }],
      },
      permissionProps: {
        children: 'children',
        label: 'name',
        disabled: 'disabled',
      },
      menuPermissions: [],
      otherPermissions: [],
    };
  },
  computed: {
  },
  created() {
    this.resetNewUser();
    this.getRoles();
    this.getList();
    if (checkPermission(['manage permission'])) {
      this.getPermissions();
    }
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    unCheckPermission(id){
      const index = this.checkedPermissionsId.indexOf(id);
      this.checkedPermissionsId.splice(index, 1);
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        region_id: '',
      };
      this.activeList = 'disponibles';
      this.handleFilter();
    },
    async getSubUsersList(subUrl) {
      this.loading = true;
      const { data } = await userResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    async setUserStatus(id, type){
      const data = await userResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.getList();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleCheckAllPermissionsChange(currentPermissions) {
      if (currentPermissions.every((val) => this.checkedPermissionsId.includes(val))){
        this.checkedPermissionsId = this.checkedPermissionsId.concat(currentPermissions);
      } else {
        this.checkedPermissionsId = this.checkedPermissionsId.filter((el) => !currentPermissions.includes(el));
      }
      this.isIndeterminate = false;
    },
    handleCheckedPermissionChange(type, currentPermissions) {
      if (type === 'manage'){
        if (!currentPermissions.every((val) => this.checkedPermissionsId.includes(val))){
          this.checkedPermissionsId = [...new Set([...this.checkedPermissionsId, ...currentPermissions])];
        } else {
          this.checkedPermissionsId = this.checkedPermissionsId.filter((el) => !currentPermissions.includes(el));
        }
      }
      this.checkedPermissionsId = [...new Set([...this.checkedPermissionsId, ...this.permissionsHeritedFromRole])];
    },
    filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.name.indexOf(value) !== -1;
    },
    handleCloseRoleDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    handleCloseUserCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelRoleForm() {
      this.$refs.RoleCreationDrawer.closeDrawer();
    },
    resetNewRole() {
      this.editing = false;
      this.newRole = {
        name: '',
      };
    },
    async getRoles() {
      this.loading = true;
      const { data } = await roleResource.list(this.query);
      this.roles = data;
      this.nonAdminRoles = [];
      this.roles.forEach(role => {
        if (role.name.toLowerCase() !== 'admin') {
          this.nonAdminRoles.push(role);
        }
      });
      this.loading = false;
      this.setSta();
    },
    getRoleUserCount(roleName){
      const found = this.roles.find(role => role.name === roleName);
      if (found) {
        return parseInt(found.userscount);
      } else {
        return 0;
      }
    },
    onUserRoleSelectChange(){
      this.checkedPermissionsId = [];
      this.permissionsHeritedFromRole = [];
      const rolePermissionsID = [];
      // const found = this.roles.find(role => role.id === id);
      const found = this.roles.find(role => role.name === this.currentUser.roles);
      const normalizedNames = [];
      found.permissions.forEach(permission => {
        rolePermissionsID.push(permission.id);
        const normalizedName = this.normalizeLabel(permission.name);
        if (normalizedName != null && !normalizedNames.includes(normalizedName)){
          normalizedNames.push(normalizedName);
        }
      });
      this.normalizedPermissions = this.allnormalizedPermissions;
      this.selectedPermissionNames = normalizedNames;
      this.permissionsHeritedFromRole = rolePermissionsID;
      this.checkedPermissionsId = [...new Set([...this.currentUserNoHeritedPermissions, ...this.permissionsHeritedFromRole])];
      this.showOnlySelected();
    },

    isPermissionHerited(permissionID){
      return this.permissionsHeritedFromRole.includes(permissionID);
    },

    getPermissionColor(permissionName){
      if (permissionName.startsWith('create')) {
        return 'primary';
      } else if (permissionName.startsWith('edit')) {
        return 'warning';
      } else if (permissionName.startsWith('delete')) {
        return 'danger';
      } else if (permissionName.startsWith('manage')) {
        return 'success';
      } else if (permissionName.startsWith('activate')) {
        return 'primary';
      } else if (permissionName.startsWith('hide')) {
        return 'info';
      } else {
        return 'primary';
      }
    },
    getSelectedPermissionName(permissionID){
      const foundPermission = this.permissions.find(permission => permission.id === permissionID);
      return { name: foundPermission.name, type: this.getPermissionColor(foundPermission.name) };
    },
    showOnlySelected(){
      const selected = [];
      this.selectedPermissionNames.forEach(permissionName => {
        const foundPermission = this.allnormalizedPermissions.find(permission => permission.name === permissionName);
        selected.push(foundPermission);
      });
      if (!this.isEmptyArray(selected)) {
        this.normalizedPermissions = selected;
      } else {
        this.normalizedPermissions = this.allnormalizedPermissions;
      }
    },
    async getPermissions() {
      this.normalizedPermissions = [];
      this.permissionLabels = [];
      const { data } = await permissionResource.list({});
      const all = data;
      this.permissions = all;
      all.forEach(permission => {
        const normalizedName = this.normalizeLabel(permission.name);
        if (normalizedName != null && !this.normalizedPermissionNames.includes(normalizedName)){
          this.normalizedPermissionNames.push(normalizedName);
        }
        this.permissionLabels.push(permission.name);
      });

      this.normalizedPermissionNames.forEach(permissionName => {
        var element = { name: permissionName, permissions: [], permissionsIds: [] };
        all.forEach(permission => {
          if (permission.name.toLowerCase().includes(permissionName.toLowerCase())) {
            element.name = permissionName;
            element.permissions.push({ id: permission.id, name: permission.name.toLowerCase().replace(' ' + permissionName.toLowerCase(), '') });
            element.permissionsIds.push(permission.id);
          }
        });
        this.normalizedPermissions.push(element);
        this.allnormalizedPermissions = this.normalizedPermissions;
      });
    },
    checkTakenPermission(name){
      return this.permissionLabels.includes(name);
    },
    checkTakenPermissionName(name){
      if (name == null) {
        return true;
      }
      return this.normalizedPermissionNames.includes(this.$options.filters.uppercaseFirst(name.toLowerCase()));
    },
    normalizeLabel(name) {
      var normalizedName;
      if (name.startsWith('create')) {
        normalizedName = name.substring(7);
      } else if (name.startsWith('edit')) {
        normalizedName = name.substring(5);
      } else if (name.startsWith('delete')) {
        normalizedName = name.substring(7);
      } else if (name.startsWith('manage')) {
        normalizedName = name.substring(7);
      } else if (name.startsWith('activate')) {
        normalizedName = name.substring(9);
      } else if (name.startsWith('hide')) {
        normalizedName = name.substring(5);
      } else if (name.startsWith('list')) {
        normalizedName = name.substring(5);
      } else {
        normalizedName = null;
      }
      return this.$options.filters.uppercaseFirst(normalizedName);
    },
    permissionsStandard(permissions) {
      const permissionsStandards = [];
      permissions.forEach(permission => {
        const permissionName = permission.name;
        if (permissionName.startsWith('create') || permissionName.startsWith('edit') || permissionName.startsWith('delete') || permissionName.startsWith('manage') || permissionName.startsWith('activate') || permissionName.startsWith('hide') || permissionName.startsWith('list')) {
          permissionsStandards.push(permission);
        }
      });
      return permissionsStandards;
    },

    handleDeleteRole(id) {
      roleResource.destroy(id).then(response => {
        this.$message({
          type: 'success',
          message: this.$t('roles.suppressionEffectue'),
        });
        this.handleFilter();
      }).catch(error => {
        console.log(error);
      });
    },

    handleDeletePermission(id) {
      this.permissionTableLoading = true;
      permissionResource.destroy(id).then(response => {
        this.$message({
          type: 'success',
          message: this.$t('permission.suppressionEffectue'),
        });
        this.getPermissions();
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.permissionTableLoading = false;
      });
    },
    setSta(){
      var allUsers = 0;
      this.roles.forEach(role => {
        allUsers += role.userscount;
      });
      this.adminsCount = this.getRoleUserCount('admin');
      this.donorsCount = this.getRoleUserCount('donor');
      this.recipientsCount = this.getRoleUserCount('recipient');
      this.othersCount = allUsers - (this.adminsCount + this.donorsCount + this.recipientsCount);
    },
    havingRole(roleName){
      delete this.query.excludesroles;
      const found = this.roles.find(role => role.name === roleName);
      this.query.role = found.id;
      this.handleFilter();
    },
    excludeRole(roles){
      this.query.role = null;
      const rolestoexclude = roles.split(',');
      this.query.excludesroles = rolestoexclude;
      this.handleFilter();
    },
    async getList() {
      const { limit, page } = this.query;
      this.loading = true;
      const { data, meta } = await userResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.loading = false;
      this.total = meta.total;
      this.getRoles();
    },
    handleFilter() {
      this.query.page = 1;
      this.getList();
    },
    handleCreate() {
      this.normalizedPermissions = this.allnormalizedPermissions;
      this.selectedPermissionNames = [];
      this.checkedPermissionsId = [];
      this.permissionsHeritedFromRole = [];
      this.currentUserNoHeritedPermissions = [];
      this.editing = false;
      this.resetNewUser();
      this.dialogCreateUser = true;
      this.$nextTick(() => {
        this.$refs['userForm'].clearValidate();
      });
    },
    handleDeleteUser(id, visible, name) {
      this.$confirm(this.$t('user.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer user']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              userResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('user.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setUserStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    async handleEditPermissions(id) {
      this.loading = true;
      this.checkedPermissionsId = [];
      this.permissionsHeritedFromRole = [];
      this.editing = true;
      this.currentUserId = id;
      const found = this.list.find(user => user.id === id);
      const { data } = await userResource.permissions(id);
      this.currentUser = {
        id: found.id,
        name: found.name,
        email: found.email,
        username: found.username,
        roles: found.roles[0],
        permissions: data,
      };
      const fromRolePermissionsIds = [];
      const userPermissionsIds = [];
      data.role.forEach(permission => {
        if (!fromRolePermissionsIds.includes(permission.id)) {
          fromRolePermissionsIds.push(permission.id);
        }
      });
      data.user.forEach(permission => {
        if (!userPermissionsIds.includes(permission.id)) {
          userPermissionsIds.push(permission.id);
        }
      });

      const normalizedNames = [];
      const allUserPermissions = [...new Set([...data.user, ...data.role])];
      allUserPermissions.forEach(permission => {
        const normalizedName = this.normalizeLabel(permission.name);
        if (normalizedName != null && !normalizedNames.includes(normalizedName)){
          normalizedNames.push(normalizedName);
        }
      });
      this.normalizedPermissions = this.allnormalizedPermissions;
      this.selectedPermissionNames = normalizedNames;
      this.currentUserNoHeritedPermissions = userPermissionsIds;
      this.permissionsHeritedFromRole = fromRolePermissionsIds;
      this.checkedPermissionsId = [...new Set([...userPermissionsIds, ...fromRolePermissionsIds])];
      this.$nextTick(() => {
        this.$refs['userForm'].clearValidate();
      });
      this.showOnlySelected();
      this.dialogCreateUser = true;
      this.loading = false;
    },
    createUser() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.currentUser.roles = [this.currentUser.roles];
          this.userCreating = true;
          userResource
            .store(this.currentUser)
            .then(async(response) => {
              this.$message({
                message: this.$t('user.NewUser') + ' ' + this.currentUser.name + ' (' + this.currentUser.email + ') ' + this.$t('user.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentUserId = response.data.id;
              this.confirmPermissions();
              this.resetNewUser();
              this.dialogCreateUser = false;
              this.handleFilter();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.userCreating = false;
            });
        } else {
          return false;
        }
      });
    },
    confirmPermissions() {
      const checkedPermissions = this.checkedPermissionsId.filter((el) => !this.permissionsHeritedFromRole.includes(el));
      userResource.updatePermission(this.currentUserId, { permissions: checkedPermissions }).then(response => {
        this.$message({
          message: this.$t('user.PermissionsUpdated'),
          type: 'success',
          duration: 5 * 1000,
        });
      });
    },
    updateUser() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.userCreating = true;
          userResource.update(this.currentUserId, this.currentUser).then(response => {
            this.confirmPermissions();
            this.resetNewUser();
            this.handleFilter();
            this.$message({
              message: this.$t('user.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateUser = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.userCreating = false;
            });
        } else {
          return false;
        }
      });
    },
    resetNewUser() {
      this.currentUser = {
        name: '',
        email: '',
        username: '',
        password: '',
        c_password: '',
        roles: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('user.nom'), this.$t('user.email'), this.$t('user.role')];
        const filterVal = ['id', 'nom', 'email', 'roles'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des users SOYCAIN_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    permissionKeys(permissions) {
      return permissions.map(permssion => permssion.id);
    },

    normalizeMenuPermission(permission) {
      return { id: permission.id, name: this.$options.filters.uppercaseFirst(permission.name.substring(10)), disabled: permission.disabled || false };
    },

    normalizePermission(permission) {
      const disabled = permission.disabled || permission.name === 'manage permission';
      return { id: permission.id, name: this.$options.filters.uppercaseFirst(permission.name), disabled: disabled };
    },

    confirmPermission() {
      const checkedMenu = this.$refs.menuPermissions.getCheckedKeys();
      const checkedOther = this.$refs.otherPermissions.getCheckedKeys();
      const checkedPermissions = checkedMenu.concat(checkedOther);
      this.dialogPermissionLoading = true;

      userResource.updatePermission(this.currentUserId, { permissions: checkedPermissions }).then(response => {
        this.$message({
          message: this.$t('user.PermissionsUpdated'),
          type: 'success',
          duration: 5 * 1000,
        });
        this.dialogPermissionLoading = false;
        this.dialogPermissionVisible = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
